import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

const CLIENT_ID = process.env.REACT_APP_GCP_webApp_clientId;
const SCOPES = "https://www.googleapis.com/auth/analytics.readonly";

const GoogleAnalyticsSelector = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    const initClient = () => {
      gapi.load("client:auth2", () => {
        gapi.client.init({
          clientId: CLIENT_ID,
          scope: SCOPES,
        });
      });
    };

    initClient();
    return () => unsubscribe();
  }, []);

  const handleAuthClick = async () => {
    const auth2 = gapi.auth2.getAuthInstance();
    try {
      await auth2.signIn();
      const user = auth2.currentUser.get();
      const token = user.getAuthResponse().access_token;

      console.log("Access Token:", token);

      if (currentUser) {
        const userRef = doc(db, "users", currentUser.email);
        await setDoc(userRef, { gaAccessToken: token }, { merge: true });
        console.log("Google Analytics token saved to Firestore.");
      } else {
        console.warn("No authenticated user found. Cannot save token.");
      }

      navigate("/profile"); // Redirect back to Profile after saving token
    } catch (error) {
      console.error("Error during Google Analytics sign-in:", error);
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold text-gray-900">
          Setup Your Google Analytics Integration
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Select your Google Analytics Property</p>
        </div>
        <div className="mt-5">
          <button
            type="button"
            onClick={handleAuthClick}
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleAnalyticsSelector;
