import React from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";

const PrivateRoute = ({ element: Component }) => {
  const isAuthenticated = !!auth.currentUser;
  return isAuthenticated ? Component : <Navigate to="/" />;
};


export default PrivateRoute;
