import React, { useEffect, useState } from "react";

const PageviewsReport = ({ gaAccessToken }) => {
  const [accounts, setAccounts] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);

  // Fetch accounts when the token is available
  useEffect(() => {
    if (!gaAccessToken) return;

    const fetchAccounts = async () => {
      try {
        const res = await fetch("https://analyticsadmin.googleapis.com/v1beta/accounts", {
          headers: {
            Authorization: `Bearer ${gaAccessToken}`,
          },
        });

        if (!res.ok) {
          const errorData = await res.json();
          throw new Error(errorData.error?.message || "Failed to fetch accounts");
        }

        const data = await res.json();
        const fetchedAccounts = data.accounts || [];
        setAccounts(fetchedAccounts);

        // Optionally, fetch properties for the first account automatically
        if (fetchedAccounts.length > 0) {
          await fetchProperties(fetchedAccounts[0].name);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchAccounts();
  }, [gaAccessToken]);

  // Fetch properties for a selected account
  const fetchProperties = async (accountName) => {
    setProperties([]);
    setSelectedProperty("");
    setReportData(null); // Reset report when switching accounts
    setError(null);

    try {
      const url = `https://analyticsadmin.googleapis.com/v1beta/${accountName}/properties`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${gaAccessToken}`,
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error?.message || "Failed to fetch properties");
      }

      const data = await res.json();
      const fetchedProperties = data.properties || [];
      setProperties(fetchedProperties);
    } catch (err) {
      setError(err.message);
    }
  };

  // Fetch the report for a selected property
  const fetchReport = async (propertyResourceName) => {
    setReportData(null);
    setError(null);

    if (!propertyResourceName) return;

    try {
      const res = await fetch("https://analyticsdata.googleapis.com/v1beta:runReport", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${gaAccessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          property: propertyResourceName, // e.g., "properties/123456789"
          dateRanges: [{ startDate: "7daysAgo", endDate: "today" }],
          dimensions: [{ name: "pagePath" }],
          metrics: [{ name: "screenPageViews" }],
          limit: 50,
        }),
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error?.message || "Failed to fetch report");
      }

      const data = await res.json();
      setReportData(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handlePropertyChange = (e) => {
    const selectedPropName = e.target.value; // e.g., "properties/123456789"
    setSelectedProperty(selectedPropName);
    fetchReport(selectedPropName);
  };

  if (!gaAccessToken) {
    return <p>Please provide a valid Google Analytics access token.</p>;
  }

  return (
    <div>
      <h2>Google Analytics Account/Property Selector</h2>

      {error && <p style={{ color: "red" }}>Error: {error}</p>}

      {/* Account Selector */}
      {accounts.length > 0 && (
        <div>
          <label>
            Account:
            <select
              onChange={(e) => fetchProperties(e.target.value)}
              style={{ marginLeft: "10px" }}
            >
              <option value="">Select an account</option>
              {accounts.map((account) => (
                <option key={account.name} value={account.name}>
                  {account.displayName} ({account.name})
                </option>
              ))}
            </select>
          </label>
        </div>
      )}

      {/* Property Selector */}
      {properties.length > 0 && (
        <div style={{ marginTop: "10px" }}>
          <label>
            Property:
            <select
              value={selectedProperty}
              onChange={handlePropertyChange}
              style={{ marginLeft: "10px" }}
            >
              <option value="">Select a property</option>
              {properties.map((property) => (
                <option key={property.name} value={property.name}>
                  {property.displayName} ({property.name})
                </option>
              ))}
            </select>
          </label>
        </div>
      )}

      {/* Report Data */}
      {selectedProperty && reportData && (
        <div style={{ marginTop: "20px" }}>
          <h2>Pageviews (Last 7 Days)</h2>
          <table border="1" cellPadding="8">
            <thead>
              <tr>
                <th>Page Path</th>
                <th>Pageviews</th>
              </tr>
            </thead>
            <tbody>
              {reportData.rows && reportData.rows.length > 0 ? (
                reportData.rows.map((row, index) => (
                  <tr key={index}>
                    <td>{row.dimensionValues[0]?.value || "N/A"}</td>
                    <td>{row.metricValues[0]?.value || "0"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No data found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {selectedProperty && !reportData && !error && <p>Loading report data...</p>}
    </div>
  );
};

export default PageviewsReport;
